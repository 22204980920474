@import '../../../../styles/mixins';

.font-align {
  h4 {
    text-align: center;
  }
  .font-align-container {
    .font-align-container-box {
      padding: 0 20px;
      width: 400px;
      margin: 0 auto;
      line-height: 150px;
      background-color: #eef0f4;
      .font-align-container-content {
        display: inline-block;
        line-height: 20px;
        text-align: left;
        vertical-align: middle;
      }
    }
  }
}
