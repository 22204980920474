@import '../../../../styles/mixins';

.taiji {
  .taiji-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .taiji-container-content-split {
      position: relative;
      width: 100px;
      height: 100px;
      background-color: #000;
      border-radius: 50%;
      box-shadow: 0 5px 2px #ccc;
      margin-right: 40px;
    }
    .taiji-container-content {
      position: relative;
      width: 100px;
      height: 100px;
      background-color: #000;
      border-radius: 50%;
      box-shadow: 0 5px 2px #ccc;
      + .taiji-container-content {
        margin-left: 40px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50px;
        display: block;
        width: 100px;
        height: 50px;
        background-color: #fff;
        border-radius: 0 0 50px 50px;
      }
      .taiji-container-content-yin,
      .taiji-container-content-yang {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #000;
        border-radius: 50%;
        top: 25px;
        z-index: 1;
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 10px;
          height: 10px;
          background-color: #fff;
          border-radius: 50%;
          top: 20px;
          left: 20px;
        }
      }
      .taiji-container-content-yin-split,
      .taiji-container-content-yang-split {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #000;
        border-radius: 50%;
        top: 25px;
        z-index: 1;
      }
      .taiji-container-content-yang {
        background-color: #fff;
        right: 0;
        &:after {
          background-color: #000;
        }
      }
      .taiji-container-content-yang-split {
        background-color: #fff;
        right: 0;
      }
      .taiji-container-content-yinyang {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 20px solid #000;
        background-color: #fff;
        top: 25px;
        z-index: 1;
        &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 20px solid #fff;
          background-color: #000;
          top: -20px;
          left: 30px;
          z-index: 1;
        }
      }
      .taiji-container-content-yinyang-split {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 20px solid #000;
        background-color: #fff;
        top: 25px;
        z-index: 1;
      }
    }
  }
}
