@import './mixins';

// Here you put all global css rules.

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif SC', 'Source Han Serif SC', 'Source Han Serif', source-han-serif-sc,
    'PT Serif', 'SongTi SC', 'MicroSoft Yahei', Georgia, serif;
  a {
    text-decoration: unset;
    text-decoration-color: unset;
    color: unset;
  }
}
