@import '../../styles/mixins';
@header-height: 64px;
@back-height: 50px;
.css-layout {
  .css-page-container {
    padding: 40px 300px 0 300px;
  }
  .css-header {
    z-index: 3;
    background-color: #fff;
    width: 100%;
    position: fixed;
    text-align: center;
    font-size: 20px;
    line-height: @header-height;
    box-shadow: 0 1px 40px -8px #00000080;
    .css-header-image {
      float: left;
      margin-top: 12px;
      margin-left: 12px;
    }
  }
  .css-header-blank {
    padding-top: @header-height;
  }
  .css-header-back-top {
    position: fixed;
    bottom: 80px;
    right: 80px;
    .css-header-back-top-btn {
      display: inline-block;
      color: #99cccc;
      background-color: #ccffcc;
      width: @back-height;
      height: @back-height;
      line-height: @back-height;
      border: 6px ridge #ccffcc;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px #99cccc, 0 1px, 0 2px, 0 3px, 0 4px;
      text-align: center;
      -webkit-transition: border-color 0.25s, background-color 0.25s;
      transition: border-color 0.25s, background-color 0.25s;
      font-size: 24px;
      cursor: pointer;
      writing-mode: vertical-rl;
      &:active {
        text-indent: 2px;
      }
    }
  }
}
