@import '../../../../styles/mixins';

@font-face {
  font-family: 'my';
  font-weight: 100;
  src: local('FZShuTi');
}
@font-face {
  font-family: 'my';
  font-weight: 200;
  src: local('STXingkau');
}
@font-face {
  font-family: 'my';
  font-weight: 300;
  src: local('STXihei');
}
@font-face {
  font-family: 'my';
  font-weight: 400;
  src: local('STCaiyun');
}
@font-face {
  font-family: 'my';
  font-weight: 500;
  src: local('STSong');
}
@font-face {
  font-family: 'my';
  font-weight: 600;
  src: local('STFangsong');
}
@font-face {
  font-family: 'my';
  font-weight: 700;
  src: local('STHupo');
}
@font-face {
  font-family: 'my';
  font-weight: 800;
  src: local('STKaiti');
}
@font-face {
  font-family: 'my';
  font-weight: 900;
  src: local('FZShuTi');
}

.font-weight {
  text-align: center;
  .font-weight-container {
    + .font-weight-container {
      padding-top: 20px;
      border-top: 1px dashed;
    }
    .font-weight-mb {
      margin-bottom: 20px;
    }
    .font-weight-defined {
      margin: 10px 0;
    }
    .font-weight-normal {
      margin: 10px 0;
    }
    .font-weight-container-box {
      .font-weight-container-content {
      }
    }
  }
}
