@import '../../../../styles/mixins';

.content {
  h4 {
    text-align: center;
  }
  .content-container {
    .content-container-box {
      width: 256px;
      height: 256px;
      margin: auto;
      border-bottom: 1px dashed #ccc;
      text-align: justify;
      .bar {
        height: 0;
        display: inline-block;
        width: 20px;
        transition: height 0.25s 0.2s;
      }
      &:before {
        content: '';
        height: 100%;
        display: inline-block;
      }
      &:after {
        content: '';
        width: 100%;
        display: inline-block;
      }
    }
  }
}
