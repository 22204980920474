@import '../../../../styles/mixins';

.position {
  .position-format-width {
    text-align: center;
    .margin-top {
      margin-top: 60px;
    }
    .position-left {
      left: 40px;
      right: 90px;
    }
    .position-absolute {
      line-height: 40px;
      position: absolute;
    }
    .bg-purple {
      background-color: #e8e8ff;
    }
  }
}
