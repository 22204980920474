@import '../../../../styles/mixins';

.text-direction {
  h4 {
    text-align: center;
  }
  .text-direction-container {
    margin: 0 auto;
    width: 500px;
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
