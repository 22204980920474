@import '../../../../styles/mixins';

.margin {
  text-align: center;
  .margin-container {
    border-top: 1px dashed;
    margin-top: 20px;
    &:last-child {
      margin-bottom: 40px;
    }
    .margin-container-content {
      display: flex;
      justify-content: space-around;
    }
    .margin-desc {
      margin: 20px auto 0 auto;
      width: 400px;
      border-top: 1px dashed;
      padding-top: 10px;
    }
    .margin-container-father {
      margin: 0 auto;
      width: 300px;
      height: 200px;
      background-color: #2db7f5;
      .margin-container-son {
        height: 150px;
        background-color: #beceeb;
      }
    }
    .margin-container-father-white {
      background-color: #fff;
    }
    .margin-container-null-father {
      overflow: hidden;
      background-color: #beceeb;
      margin: 0 auto;
      width: 300px;
    }
    .margin-container-button {
      margin-left: 20px;
      margin-top: 20px;
    }
    .margin-container-kitty {
      background-color: #beceeb;
      margin: 0 auto;
      width: 300px;
      height: 200px;
      overflow: hidden;
      overflow-y: auto;
      img {
        margin-bottom: 20px;
      }
    }
    .margin-container-box {
      width: 200px;
      height: 150px;
      background-color: #89c6fc;
    }
    .margin-container-left {
      margin: 20px 0;
    }
    .margin-container-center {
      margin: 20px auto;
    }
    .margin-container-right {
      margin: 20px 0 20px auto;
    }
    .margin-container-absolute {
      position: relative;
      margin: 20px auto;
      .margin-container-absolute-inner {
        position: absolute;
        width: 150px;
        height: 100px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #beceeb;
        margin: auto;
      }
    }
  }
}

.margin-container-absolute {
  position: relative;
  margin: 20px auto;
  .margin-container-absolute-inner {
    position: absolute;
    width: 150px;
    height: 100px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #beceeb;
    margin: auto;
  }
}
