@import '../../../../styles/mixins';

.padding {
  text-align: center;
  .padding-container {
    + .padding-container {
      margin-top: 40px;
    }
    .padding-container-box {
      .padding-container-box-login {
        padding-right: 10px;
        border-right: 1px solid gray;
      }
      .padding-container-box-register {
        padding-left: 10px;
      }
    }
    .padding-container-content {
      margin-top: 20px;
      .padding-container-content-box {
        + .padding-container-content-box {
          &:before {
            content: '';
            border-left: 1px solid gray;
            margin-left: 8px;
            padding: 12px 4px 1px;
            font-size: 0;
          }
        }
      }
    }
    .padding-container-menu {
      display: inline-block;
      height: 10px;
      width: 140px;
      padding: 35px 0;
      border-top: 10px solid;
      border-bottom: 10px solid;
      background-color: #000;
      background-clip: content-box;
    }
    .padding-container-dot {
      margin-left: 50px;
      display: inline-block;
      height: 60px;
      width: 60px;
      padding: 10px;
      border: 10px solid;
      border-radius: 50%;
      background-color: #000;
      background-clip: content-box;
    }
  }
}
