@import '../../../../styles/mixins';

.float {
  .float-container {
    width: 600px;
    margin: 0 auto;
    .float-container-around {
      margin-bottom: 20px;
      width: 200px;
      .float-container-around-reset {
        margin-left: 10px;
      }
    }
    .float-container-table {
      border-top: 1px dashed;
      .margin-left {
        margin-left: 20px;
      }
      .margin-top {
        margin-top: 20px;
      }
      .float-container-table-box {
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 20px;
        text-align: center;
        background-color: #f1ccb8;
        float: left;
      }
    }
  }
}
