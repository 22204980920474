body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif SC', 'Source Han Serif SC', 'Source Han Serif', source-han-serif-sc, 'PT Serif', 'SongTi SC', 'MicroSoft Yahei', Georgia, serif;
}
body a {
  text-decoration: unset;
  -webkit-text-decoration-color: unset;
          text-decoration-color: unset;
  color: unset;
}
.home-welcome-page {
  text-align: center;
}
.home-welcome-page .app {
  text-align: center;
}
.home-welcome-page .rekit-logo {
  -webkit-animation: app-logo-spin infinite 10s linear;
          animation: app-logo-spin infinite 10s linear;
  height: 80px;
}
.home-welcome-page .app-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  overflow: hidden;
  position: relative;
}
.home-welcome-page .app-title {
  font-size: 1.5em;
}
.home-welcome-page .app-intro {
  font-size: large;
}
.home-welcome-page ul,
.home-welcome-page li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.home-welcome-page ul {
  margin-top: 20px;
}
.home-welcome-page li {
  margin-top: 10px;
}
.home-welcome-page a {
  color: #0288d1;
  text-decoration: none;
}
.home-welcome-page a:hover {
  text-decoration: underline;
}
.home-welcome-page p.memo {
  width: 500px;
  color: #999;
  font-size: 12px;
  line-height: 150%;
  margin: auto;
}
@-webkit-keyframes app-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes app-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.my-input {
  width: 180px;
  font-size: 14px;
}
.my-input .my-input-inner {
  border: 1px solid #dcdfe6;
  padding: 0 15px;
  color: #606266;
  border-radius: 4px;
  line-height: 40px;
  cursor: pointer;
}
.my-input .my-input-inner:focus {
  outline: none;
  border-color: #409eff;
  cursor: text;
}
.my-index-container {
  display: flex;
  margin: 0 auto;
  width: 800px;
  height: 300px;
  box-shadow: 0 1px 20px -6px #00000080;
  border-radius: 10px;
}
.my-index-container .my-index-container-content {
  padding: 15px 20px;
  width: 350px;
}
.my-index-container .my-index-container-content h3 {
  border-bottom: 1px dashed;
  padding: 0 0 10px 0;
  margin: 10px 0;
  cursor: pointer;
}
.my-index-container .my-index-container-content h3:hover {
  color: blue;
}
.my-index-container .my-index-container-content-tags {
  font-size: 12px;
  color: #cd0000;
}
.my-index-container .my-index-container-content-tags .my-index-container-content-tag {
  padding: 2px;
  margin-left: 5px;
}
.my-index-container .my-index-container-content-detail p {
  margin: 10px 0;
}
.my-index-container .my-index-container-pic {
  object-fit: cover;
  overflow: hidden;
  width: 450px;
  height: 300px;
}
.examples-side-panel {
  position: fixed;
  box-sizing: border-box;
  overflow: auto;
  top: 0;
  left: 0;
  margin: 0;
  padding: 40px;
  width: 260px;
  height: 100%;
  background-color: #f7f7f7;
}
.examples-side-panel ul,
.examples-side-panel li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.examples-side-panel li {
  padding: 8px;
}
.examples-side-panel a {
  color: #2db7f5;
  text-decoration: none;
}
.examples-side-panel a:hover {
  color: #f90;
}
.examples-side-panel .memo {
  font-size: 13px;
  margin-top: 40px;
  line-height: 150%;
  color: #aaa;
}
.examples-side-panel .memo:before {
  content: ' ';
  display: block;
  height: 2px;
  width: 60px;
  margin-bottom: 10px;
  background-color: #ddd;
}
.examples-welcome-page {
  line-height: 160%;
  position: relative;
  color: #555;
}
.examples-welcome-page a {
  color: #2db7f5;
  text-decoration: none;
}
.examples-welcome-page a:hover {
  color: #f90;
}
.examples-welcome-page .app-logo {
  position: absolute;
  top: -14px;
  left: 0px;
  width: 50px;
}
.examples-welcome-page h1 {
  padding-left: 60px;
  margin-bottom: 40px;
  font-size: 28px;
}
.examples-welcome-page h3 {
  margin-top: 20px;
}
.examples-welcome-page code {
  font-size: 14px;
}
.examples-counter-page {
  color: #555;
}
.examples-counter-page h1 {
  margin-top: 0px;
  font-size: 28px;
}
.examples-counter-page span {
  padding: 0 10px;
  display: inline-block;
  min-width: 30px;
  text-align: center;
}
.examples-counter-page button.btn-reset {
  margin-left: 15px;
}
.examples-reddit-list-page {
  color: #555;
}
.examples-reddit-list-page h1 {
  margin-top: 0px;
  font-size: 28px;
}
.examples-reddit-list-page a {
  color: #2db7f5;
  text-decoration: none;
}
.examples-reddit-list-page a:hover {
  color: #f90;
}
.examples-reddit-list-page .fetch-list-error {
  display: block;
  margin-top: 20px;
  color: red;
}
.examples-reddit-list-page .no-items-tip {
  margin-top: 15px;
}
.examples-layout .examples-page-container {
  padding: 40px 30px 0 300px;
  min-width: 400px;
  max-width: 700px;
}
.css-home-page {
  line-height: 160%;
  color: #555;
  width: 880px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
}
.css-home-page .css-home-page-timeline:nth-child(odd) {
  padding: 30px 0 30px 30px;
}
.css-home-page .css-home-page-timeline:nth-child(odd)::before {
  left: 0;
  top: -2px;
  bottom: -2px;
  border-width: 5px 0 5px 5px;
  border-radius: 50px 0 0 50px;
}
.css-home-page .css-home-page-timeline:nth-child(odd) h3::before {
  left: -81px;
}
.css-home-page .css-home-page-timeline:nth-child(odd) .my-index-container-content {
  border-radius: 10px 0 0 10px;
}
.css-home-page .css-home-page-timeline:nth-child(odd) .my-index-container-pic {
  border-radius: 0 10px 10px 0;
}
.css-home-page .css-home-page-timeline:nth-child(odd) .my-index-container-pic img {
  height: 300px;
}
.css-home-page .css-home-page-timeline:nth-child(even) {
  padding: 30px 30px 30px 0;
}
.css-home-page .css-home-page-timeline:nth-child(even)::before {
  right: 0;
  top: -3px;
  bottom: -3px;
  border-width: 5px 5px 5px 0;
  border-radius: 0 50px 50px 0;
}
.css-home-page .css-home-page-timeline:nth-child(even) h3::before {
  right: -81px;
}
.css-home-page .css-home-page-timeline:nth-child(even) .my-index-container {
  flex-direction: row-reverse;
}
.css-home-page .css-home-page-timeline:nth-child(even) .my-index-container-pic {
  border-radius: 10px 0 0 10px;
}
.css-home-page .css-home-page-timeline:nth-child(even) .my-index-container-pic img {
  height: 300px;
}
.css-home-page .css-home-page-timeline:nth-child(even) .my-index-container-content {
  border-radius: 0 10px 10px 0;
}
.css-home-page .css-home-page-timeline:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}
.css-home-page .css-home-page-timeline {
  position: relative;
}
.css-home-page .css-home-page-timeline::before {
  content: '';
  position: absolute;
  width: 50%;
  border: solid orangered;
}
.css-home-page .css-home-page-timeline h3 {
  position: relative;
}
.css-home-page .css-home-page-timeline h3::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border: 3px solid orange;
  border-radius: 100%;
  background-color: #fff;
}
.css-layout .css-page-container {
  padding: 40px 300px 0 300px;
}
.css-layout .css-header {
  z-index: 3;
  background-color: #fff;
  width: 100%;
  position: fixed;
  text-align: center;
  font-size: 20px;
  line-height: 64px;
  box-shadow: 0 1px 40px -8px #00000080;
}
.css-layout .css-header .css-header-image {
  float: left;
  margin-top: 12px;
  margin-left: 12px;
}
.css-layout .css-header-blank {
  padding-top: 64px;
}
.css-layout .css-header-back-top {
  position: fixed;
  bottom: 80px;
  right: 80px;
}
.css-layout .css-header-back-top .css-header-back-top-btn {
  display: inline-block;
  color: #99cccc;
  background-color: #ccffcc;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 6px ridge #ccffcc;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #99cccc, 0 1px, 0 2px, 0 3px, 0 4px;
  text-align: center;
  transition: border-color 0.25s, background-color 0.25s;
  font-size: 24px;
  cursor: pointer;
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
}
.css-layout .css-header-back-top .css-header-back-top-btn:active {
  text-indent: 2px;
}
.height-percent {
  text-align: center;
  font-size: 20px;
  color: #ffffff;
}
.height-percent .child::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.height-percent .father {
  height: 100px;
  background-color: #beceeb;
  padding: 30px;
}
.height-percent .father .father-desc {
  position: absolute;
  margin-top: -25px;
  font-size: 18px;
  color: #495973;
}
.height-percent .father .child {
  height: 100%;
  background-color: #cd0000;
}
.height-percent .height-percent-normal {
  margin: 0 auto;
  width: 300px;
}
.height-percent .height-percent-absolute {
  margin: 20px auto 0 auto;
  width: 300px;
}
.height-percent .height-percent-absolute .father {
  position: relative;
}
.height-percent .height-percent-absolute .father .child {
  position: absolute;
  width: 100%;
}
.height-percent .height-percent-desc {
  margin-top: 100px;
  font-size: 14px;
  color: #000000;
}
.cyy-test {
  width: calc(100vw - 4px - 100%);
  height: 20px;
  background-color: #96973d;
}
.max-height {
  display: flex;
  justify-content: space-evenly;
}
.max-height .max-height-height {
  width: 300px;
}
.max-height .max-height-height .pointer {
  cursor: pointer;
}
.max-height .max-height-height .blue-color {
  color: #34538b;
}
.max-height .max-height-height .element {
  height: 0;
  overflow: hidden;
  transition: height 0.5s;
}
.max-height .max-height-height .expand {
  height: auto;
}
.max-height .max-height-max {
  width: 300px;
}
.max-height .max-height-max .pointer {
  cursor: pointer;
}
.max-height .max-height-max .blue-color {
  color: #34538b;
}
.max-height .max-height-max .element {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}
.max-height .max-height-max .expand {
  max-height: 999px;
}
.unit-ex {
  text-align: center;
}
.unit-ex .unit-ex-container {
  margin-top: 20px;
}
.unit-ex .unit-ex-container .unit-ex-mt {
  margin-top: 10px;
}
.unit-ex .unit-ex-container button + button {
  margin-left: 10px;
}
.unit-ex .unit-ex-container .unit-ex-container-icon {
  display: inline-block;
  width: 20px;
  height: 1ex;
  background: url(/static/media/arrow.baa2d64f.svg) no-repeat center / 20px 20px;
}
.width-auto {
  padding-bottom: 20px;
  border: 1px dashed #495973;
  text-align: center;
}
.width-auto .flex {
  display: flex;
  justify-content: space-evenly;
}
.width-auto .width-zero {
  width: 0;
}
.width-auto .bg-purple {
  background-color: #e8e8ff;
}
.width-auto .bg-green {
  background-color: #d7fff0;
}
.width-auto .border-bottom {
  border-bottom: 1px dashed #495973;
}
.width-auto .width-auto-fill-available .fill-available {
  line-height: 40px;
}
.width-auto .width-auto-shrink-to-fit .shrink-to-fit {
  position: absolute;
  line-height: 40px;
}
.width-auto .width-auto-shrink-to-fit .shrink-to-fit-p {
  display: inline-block;
}
.width-auto .width-auto-min-content table {
  width: 240px;
  text-align: left;
}
.width-auto .width-auto-over .nowrap {
  white-space: nowrap;
}
.width-auto .width-auto-over .over {
  margin-top: 20px;
}
.fill-available .text-center {
  text-align: center;
}
.fill-available .fill-available-container {
  width: 200px;
  margin: 50px auto;
}
.fill-available .fill-available-container .fill-available-div {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 10px;
  margin-top: 30px;
  word-break: break-all;
  text-align: center;
  min-height: 20px;
}
.fill-available .fill-available-container .fill-available-div .fill-available-span {
  text-align: left;
}
.fill-available .fill-available-container .fill-available-div p {
  margin: 0;
}
.fill-available .fill-available-container button {
  min-height: 25px;
  width: 200px;
  margin-top: 30px;
}
.fill-available .fill-available-desc {
  margin-top: 20px;
  text-align: left;
}
.position .position-format-width {
  text-align: center;
}
.position .position-format-width .margin-top {
  margin-top: 60px;
}
.position .position-format-width .position-left {
  left: 40px;
  right: 90px;
}
.position .position-format-width .position-absolute {
  line-height: 40px;
  position: absolute;
}
.position .position-format-width .bg-purple {
  background-color: #e8e8ff;
}
.absolute .absolute-position h4 {
  text-align: center;
}
.absolute .absolute-position h5 {
  font-size: 14px;
}
.absolute .absolute-position .absolute {
  position: absolute;
}
.absolute .absolute-position .absolute-position-container {
  width: 240px;
  margin: 20px auto;
}
.absolute .absolute-position-dialog {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 400px;
  height: 300px;
  background-color: #b9f2d8;
  margin: auto;
}
.absolute .absolute-position-dialog p {
  margin: 2px;
}
.absolute .showDialog {
  margin-bottom: 20px;
  cursor: pointer;
  color: #34538b;
}
.overflow-hidden .overflow-hidden-container {
  text-align: center;
  font-size: 14px;
}
.overflow-hidden .overflow-hidden-container .grey {
  background-color: #ddd;
}
.overflow-hidden .overflow-hidden-container .blue {
  background-color: #89c6fc;
}
.overflow-hidden .overflow-hidden-container .green {
  background-color: #b9f2d8;
}
.overflow-hidden .overflow-hidden-container .orange {
  background-color: #fccba2;
}
.overflow-hidden .overflow-hidden-container .overflow-hidden-tab {
  width: 20em;
  height: 10em;
  line-height: 10em;
  border: 1px solid #ddd;
  overflow: hidden;
  margin: auto;
}
.overflow-hidden .overflow-hidden-container .overflow-hidden-tab .overflow-hidden-tabs {
  height: 100%;
  text-align: center;
  position: relative;
  font-size: 8em;
}
.overflow-hidden .overflow-hidden-container .overflow-hidden-tab .overflow-hidden-tabs input {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  border: 0;
  padding: 0;
  margin: 0;
  clip: rect(0 0 0 0);
}
.overflow-hidden .overflow-hidden-container .overflow-hidden-key {
  margin-top: 1em;
}
.overflow-hidden .overflow-hidden-container .overflow-hidden-key .overflow-hidden-keys {
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border: 1px solid #ccc;
  background: #f7f7f7;
  color: #333;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}
.overflow-hidden .overflow-hidden-container .overflow-hidden-key .active {
  background-color: #e0e0e0;
  border: 1px solid #89c6fc;
}
.overflow-hidden .overflow-hidden-container .overflow-hidden-desc {
  margin-top: 40px;
}
.float .float-container {
  width: 600px;
  margin: 0 auto;
}
.float .float-container .float-container-around {
  margin-bottom: 20px;
  width: 200px;
}
.float .float-container .float-container-around .float-container-around-reset {
  margin-left: 10px;
}
.float .float-container .float-container-table {
  border-top: 1px dashed;
}
.float .float-container .float-container-table .margin-left {
  margin-left: 20px;
}
.float .float-container .float-container-table .margin-top {
  margin-top: 20px;
}
.float .float-container .float-container-table .float-container-table-box {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 20px;
  text-align: center;
  background-color: #f1ccb8;
  float: left;
}
.replaced-element {
  text-align: center;
}
.replaced-element .replaced-element-container + .replaced-element-container {
  margin-top: 20px;
}
.replaced-element .replaced-element-container .replaced-element-container-img {
  height: 300px;
}
.replaced-element .replaced-element-container .replaced-element-container-change:hover {
  content: url(/static/media/kittyBite.3559c4a5.jpeg);
}
.content h4 {
  text-align: center;
}
.content .content-container .content-container-box {
  width: 256px;
  height: 256px;
  margin: auto;
  border-bottom: 1px dashed #ccc;
  text-align: justify;
}
.content .content-container .content-container-box .bar {
  height: 0;
  display: inline-block;
  width: 20px;
  transition: height 0.25s 0.2s;
}
.content .content-container .content-container-box:before {
  content: '';
  height: 100%;
  display: inline-block;
}
.content .content-container .content-container-box:after {
  content: '';
  width: 100%;
  display: inline-block;
}
@-webkit-keyframes dot {
  33% {
    -webkit-transform: translateY(-2em);
            transform: translateY(-2em);
  }
  66% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
  }
}
@keyframes dot {
  33% {
    -webkit-transform: translateY(-2em);
            transform: translateY(-2em);
  }
  66% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
  }
}
.loading-dot {
  text-align: center;
}
.loading-dot .loading-dot-container .loading-dot-container-dots {
  margin-top: 40px;
}
.loading-dot .loading-dot-container .loading-dot-container-dots .loading-dot-container-dot {
  display: inline-block;
  height: 1em;
  line-height: 1;
  text-align: left;
  vertical-align: -0.25em;
  overflow: hidden;
}
.loading-dot .loading-dot-container .loading-dot-container-dots .loading-dot-container-dot:before {
  display: block;
  content: '...\A..\A.';
  white-space: pre-wrap;
  -webkit-animation: dot 3s infinite step-start both;
          animation: dot 3s infinite step-start both;
}
.taiji .taiji-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.taiji .taiji-container .taiji-container-content-split {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #000;
  border-radius: 50%;
  box-shadow: 0 5px 2px #ccc;
  margin-right: 40px;
}
.taiji .taiji-container .taiji-container-content {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #000;
  border-radius: 50%;
  box-shadow: 0 5px 2px #ccc;
}
.taiji .taiji-container .taiji-container-content + .taiji-container-content {
  margin-left: 40px;
}
.taiji .taiji-container .taiji-container-content:after {
  content: '';
  position: absolute;
  top: 50px;
  display: block;
  width: 100px;
  height: 50px;
  background-color: #fff;
  border-radius: 0 0 50px 50px;
}
.taiji .taiji-container .taiji-container-content .taiji-container-content-yin,
.taiji .taiji-container .taiji-container-content .taiji-container-content-yang {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
  top: 25px;
  z-index: 1;
}
.taiji .taiji-container .taiji-container-content .taiji-container-content-yin:after,
.taiji .taiji-container .taiji-container-content .taiji-container-content-yang:after {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  top: 20px;
  left: 20px;
}
.taiji .taiji-container .taiji-container-content .taiji-container-content-yin-split,
.taiji .taiji-container .taiji-container-content .taiji-container-content-yang-split {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
  top: 25px;
  z-index: 1;
}
.taiji .taiji-container .taiji-container-content .taiji-container-content-yang {
  background-color: #fff;
  right: 0;
}
.taiji .taiji-container .taiji-container-content .taiji-container-content-yang:after {
  background-color: #000;
}
.taiji .taiji-container .taiji-container-content .taiji-container-content-yang-split {
  background-color: #fff;
  right: 0;
}
.taiji .taiji-container .taiji-container-content .taiji-container-content-yinyang {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 20px solid #000;
  background-color: #fff;
  top: 25px;
  z-index: 1;
}
.taiji .taiji-container .taiji-container-content .taiji-container-content-yinyang:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 20px solid #fff;
  background-color: #000;
  top: -20px;
  left: 30px;
  z-index: 1;
}
.taiji .taiji-container .taiji-container-content .taiji-container-content-yinyang-split {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 20px solid #000;
  background-color: #fff;
  top: 25px;
  z-index: 1;
}
.padding {
  text-align: center;
}
.padding .padding-container + .padding-container {
  margin-top: 40px;
}
.padding .padding-container .padding-container-box .padding-container-box-login {
  padding-right: 10px;
  border-right: 1px solid gray;
}
.padding .padding-container .padding-container-box .padding-container-box-register {
  padding-left: 10px;
}
.padding .padding-container .padding-container-content {
  margin-top: 20px;
}
.padding .padding-container .padding-container-content .padding-container-content-box + .padding-container-content-box:before {
  content: '';
  border-left: 1px solid gray;
  margin-left: 8px;
  padding: 12px 4px 1px;
  font-size: 0;
}
.padding .padding-container .padding-container-menu {
  display: inline-block;
  height: 10px;
  width: 140px;
  padding: 35px 0;
  border-top: 10px solid;
  border-bottom: 10px solid;
  background-color: #000;
  background-clip: content-box;
}
.padding .padding-container .padding-container-dot {
  margin-left: 50px;
  display: inline-block;
  height: 60px;
  width: 60px;
  padding: 10px;
  border: 10px solid;
  border-radius: 50%;
  background-color: #000;
  background-clip: content-box;
}
.margin {
  text-align: center;
}
.margin .margin-container {
  border-top: 1px dashed;
  margin-top: 20px;
}
.margin .margin-container:last-child {
  margin-bottom: 40px;
}
.margin .margin-container .margin-container-content {
  display: flex;
  justify-content: space-around;
}
.margin .margin-container .margin-desc {
  margin: 20px auto 0 auto;
  width: 400px;
  border-top: 1px dashed;
  padding-top: 10px;
}
.margin .margin-container .margin-container-father {
  margin: 0 auto;
  width: 300px;
  height: 200px;
  background-color: #2db7f5;
}
.margin .margin-container .margin-container-father .margin-container-son {
  height: 150px;
  background-color: #beceeb;
}
.margin .margin-container .margin-container-father-white {
  background-color: #fff;
}
.margin .margin-container .margin-container-null-father {
  overflow: hidden;
  background-color: #beceeb;
  margin: 0 auto;
  width: 300px;
}
.margin .margin-container .margin-container-button {
  margin-left: 20px;
  margin-top: 20px;
}
.margin .margin-container .margin-container-kitty {
  background-color: #beceeb;
  margin: 0 auto;
  width: 300px;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
}
.margin .margin-container .margin-container-kitty img {
  margin-bottom: 20px;
}
.margin .margin-container .margin-container-box {
  width: 200px;
  height: 150px;
  background-color: #89c6fc;
}
.margin .margin-container .margin-container-left {
  margin: 20px 0;
}
.margin .margin-container .margin-container-center {
  margin: 20px auto;
}
.margin .margin-container .margin-container-right {
  margin: 20px 0 20px auto;
}
.margin .margin-container .margin-container-absolute {
  position: relative;
  margin: 20px auto;
}
.margin .margin-container .margin-container-absolute .margin-container-absolute-inner {
  position: absolute;
  width: 150px;
  height: 100px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #beceeb;
  margin: auto;
}
.margin-container-absolute {
  position: relative;
  margin: 20px auto;
}
.margin-container-absolute .margin-container-absolute-inner {
  position: absolute;
  width: 150px;
  height: 100px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #beceeb;
  margin: auto;
}
.border {
  text-align: center;
}
.border .border-container .border-container-box {
  margin: 40px auto;
  color: #ccc;
  height: 100px;
  width: 100px;
  border: 4px dashed;
}
.border .border-container .border-container-box:before {
  content: '';
  margin-top: calc((100px - 32px) / 2);
  height: 32px;
  position: absolute;
  border-right: 4px solid;
}
.border .border-container .border-container-box:after {
  content: '';
  margin-left: calc((4px - 32px) / 2);
  margin-top: calc((100px - 4px) / 2);
  width: 32px;
  position: absolute;
  border-bottom: 4px solid;
}
.border .border-container .border-container-box:hover {
  color: #34538b;
  cursor: pointer;
}
.border .border-container .border-container-triangle {
  margin: 0 auto;
  width: 0;
  border: 20px solid;
  border-color: #f30 transparent transparent;
}
.border .border-container .border-container-tooltip {
  margin: 20px auto;
  width: 300px;
  line-height: 32px;
  background-color: #99cccc;
  border-radius: 5px;
}
.border .border-container .border-container-tooltip:before {
  position: absolute;
  content: '';
  width: 0;
  border-width: 20px 15px 0 0;
  border-style: solid;
  border-color: #99cccc transparent transparent;
  margin-top: 32px;
  margin-left: 20px;
}
.layers {
  background-color: #666;
  padding: 20px 0;
}
.layers .layers-content {
  max-width: 600px;
  margin: 0 auto;
}
.layers .layers-content + .layers-content {
  margin-top: 40px;
}
.layers .layers-content .layers-container .layers-container-box-first {
  position: relative;
  z-index: auto;
}
.layers .layers-content .layers-container .layers-container-box-first .layers-container-box-first-child {
  z-index: 2;
  position: absolute;
  background-color: #99cccc;
  width: 100px;
  height: 200px;
}
.layers .layers-content .layers-container .layers-container-box-second {
  position: relative;
  z-index: auto;
}
.layers .layers-content .layers-container .layers-container-box-second .layers-container-box-second-child {
  z-index: 1;
  position: relative;
  background-color: #89c6fc;
  width: 200px;
  height: 100px;
}
.layers .layers-content .layers-container-change {
  margin-top: 120px;
}
.layers .layers-content .layers-container-change :last-child {
  margin-left: 20px;
}
.layers .layers-content .layers-content-page {
  background: linear-gradient(to bottom, #f4f39e, #f5da41 60%, #fe6);
  padding: 30px;
  position: relative;
}
.layers .layers-content .layers-content-page h4 {
  font-weight: 400;
  margin: 0;
  text-align: center;
}
.layers .layers-content .layers-content-page .layers-content-page-title:after {
  content: '';
  width: 180px;
  height: 30px;
  margin-left: -90px;
  border: 1px solid rgba(200, 200, 200, 0.8);
  background: rgba(254, 254, 254, 0.6);
  box-shadow: 0 0 3px rgb(0 0 0%);
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
  position: absolute;
  top: -15px;
  left: 50%;
}
.text-direction h4 {
  text-align: center;
}
.text-direction .text-direction-container {
  margin: 0 auto;
  width: 500px;
}
.text-direction .text-direction-container .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.font-align h4 {
  text-align: center;
}
.font-align .font-align-container .font-align-container-box {
  padding: 0 20px;
  width: 400px;
  margin: 0 auto;
  line-height: 150px;
  background-color: #eef0f4;
}
.font-align .font-align-container .font-align-container-box .font-align-container-content {
  display: inline-block;
  line-height: 20px;
  text-align: left;
  vertical-align: middle;
}
@font-face {
  font-family: 'my';
  font-weight: 100;
  src: local('FZShuTi');
}
@font-face {
  font-family: 'my';
  font-weight: 200;
  src: local('STXingkau');
}
@font-face {
  font-family: 'my';
  font-weight: 300;
  src: local('STXihei');
}
@font-face {
  font-family: 'my';
  font-weight: 400;
  src: local('STCaiyun');
}
@font-face {
  font-family: 'my';
  font-weight: 500;
  src: local('STSong');
}
@font-face {
  font-family: 'my';
  font-weight: 600;
  src: local('STFangsong');
}
@font-face {
  font-family: 'my';
  font-weight: 700;
  src: local('STHupo');
}
@font-face {
  font-family: 'my';
  font-weight: 800;
  src: local('STKaiti');
}
@font-face {
  font-family: 'my';
  font-weight: 900;
  src: local('FZShuTi');
}
.font-weight {
  text-align: center;
}
.font-weight .font-weight-container + .font-weight-container {
  padding-top: 20px;
  border-top: 1px dashed;
}
.font-weight .font-weight-container .font-weight-mb {
  margin-bottom: 20px;
}
.font-weight .font-weight-container .font-weight-defined {
  margin: 10px 0;
}
.font-weight .font-weight-container .font-weight-normal {
  margin: 10px 0;
}
@-webkit-keyframes textIn {
  0% {
    letter-spacing: -200px;
  }
  100% {
    letter-spacing: 0;
  }
}
@keyframes textIn {
  0% {
    letter-spacing: -200px;
  }
  100% {
    letter-spacing: 0;
  }
}
.letter-spacing .letter-spacing-container {
  text-align: center;
}
.letter-spacing .letter-spacing-container .letter-spacing-container-box {
  width: 120px;
  text-align: left;
  margin: 0 auto;
  -webkit-animation: textIn 1s both;
          animation: textIn 1s both;
}

