@import '../../styles/mixins';

.css-home-page {
  line-height: 160%;
  color: #555;
  width: 880px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
  .css-home-page-timeline:nth-child(odd) {
    padding: 30px 0 30px 30px;
    &::before {
      left: 0;
      top: -2px;
      bottom: -2px;
      border-width: 5px 0 5px 5px;
      border-radius: 50px 0 0 50px;
    }
    h3::before {
      left: -81px;
    }
    .my-index-container-content {
      border-radius: 10px 0 0 10px;
    }
    .my-index-container-pic {
      border-radius: 0 10px 10px 0;
      img {
        height: 300px;
      }
    }
  }
  .css-home-page-timeline:nth-child(even) {
    padding: 30px 30px 30px 0;
    &::before {
      right: 0;
      top: -3px;
      bottom: -3px;
      border-width: 5px 5px 5px 0;
      border-radius: 0 50px 50px 0;
    }
    h3::before {
      right: -81px;
    }
    .my-index-container {
      flex-direction: row-reverse;
    }
    .my-index-container-pic {
      border-radius: 10px 0 0 10px;
      img {
        height: 300px;
      }
    }
    .my-index-container-content {
      border-radius: 0 10px 10px 0;
    }
  }
  .css-home-page-timeline:first-child {
    &::before {
      border-top: 0;
      border-top-left-radius: 0;
    }
  }
  .css-home-page-timeline {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 50%;
      border: solid orangered;
    }
    h3 {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border: 3px solid orange;
        border-radius: 100%;
        background-color: #fff;
      }
    }
  }
}
