@import '../../../../styles/mixins';

.absolute {
  .absolute-position {
    h4 {
      text-align: center;
    }
    h5 {
      font-size: 14px;
    }
    .absolute {
      position: absolute;
    }
    .absolute-position-container {
      width: 240px;
      margin: 20px auto;
    }
  }
  .absolute-position-dialog {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 400px;
    height: 300px;
    background-color: #b9f2d8;
    margin: auto;
    p {
      margin: 2px;
    }
  }
  .showDialog {
    margin-bottom: 20px;
    cursor: pointer;
    color: #34538b;
  }
}
