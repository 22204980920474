@import '../../../../styles/mixins';

.fill-available {
  .text-center {
    text-align: center;
  }
  .fill-available-container {
    width: 200px;
    margin: 50px auto;
    .fill-available-div {
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      padding: 10px;
      margin-top: 30px;
      word-break: break-all;
      text-align: center;
      min-height: 20px;
      .fill-available-span {
        text-align: left;
      }
      p {
        margin: 0;
      }
    }
    button {
      min-height: 25px;
      width: 200px;
      margin-top: 30px;
    }
  }

  .fill-available-desc {
    margin-top: 20px;
    text-align: left;
  }
}
