@import '../../../../styles/mixins';

.max-height {
  display: flex;
  justify-content: space-evenly;
  .max-height-height {
    width: 300px;
    .pointer {
      cursor: pointer;
    }
    .blue-color {
      color: #34538b;
    }
    .element {
      height: 0;
      overflow: hidden;
      transition: height .5s;
    }
    .expand {
      height: auto;
    }
  }


  .max-height-max {
    width: 300px;
    .pointer {
      cursor: pointer;
    }
    .blue-color {
      color: #34538b;
    }
    .element {
      max-height: 0;
      overflow: hidden;
      transition: max-height .5s;
    }
    .expand {
      max-height: 999px;
    }
  }
}
