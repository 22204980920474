@import '../../../../styles/mixins';

.layers {
  background-color: #666;
  padding: 20px 0;
  .layers-content {
    max-width: 600px;
    margin: 0 auto;
    + .layers-content {
      margin-top: 40px;
    }
    .layers-container {
      .layers-container-box-first {
        position: relative;
        z-index: auto;

        .layers-container-box-first-child {
          z-index: 2;
          position: absolute;
          background-color: #99cccc;
          width: 100px;
          height: 200px;
        }
      }

      .layers-container-box-second {
        position: relative;
        z-index: auto;

        .layers-container-box-second-child {
          z-index: 1;
          position: relative;
          background-color: #89c6fc;
          width: 200px;
          height: 100px;
        }
      }
    }

    .layers-container-change {
      margin-top: 120px;

      :last-child {
        margin-left: 20px;
      }
    }
    .layers-content-page {
      background: linear-gradient(to bottom, #f4f39e, #f5da41 60%, #fe6);
      padding: 30px;
      position: relative;
      h4 {
        font-weight: 400;
        margin: 0;
        text-align: center;
      }
      .layers-content-page-title {
        &:after {
          content: '';
          width: 180px;
          height: 30px;
          margin-left: -90px;
          border: 1px solid rgba(200, 200, 200, .8);
          background: rgba(254, 254, 254, 0.6);
          box-shadow: 0 0 3px rgb(0 0 0 / 10%);
          transform: rotate(-5deg);
          position: absolute;
          top: -15px;
          left: 50%;
        }
      }
    }
  }
}
